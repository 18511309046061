<template>
  <Modal v-if="changelog" @close-modal="changelog = null">
    <Table :ref="auditorTable" :columnDefs="changelogCols" :initData="changelog" />
  </Modal>
  <div v-if="bannerTxt" class="wip_banner row">
    <button id="close_wip" @click="bannerTxt = false">X</button>
    <div class="row">{{ bannerTxt }}</div>
  </div>
  <header v-if="showMenu && user?.ID || false">
    <nav id="header">
      <RouterLink id="logo" to="/">
        <img src="@/images/logo.png" alt="" onerror="this.src='/cms/images/cms/noimg.jpg'" />
      </RouterLink>
      <div id="topNav">
        <span class="pageTitle" v-html="currentPage"></span>
        <div id="btn_profile">
          <a id="profile" href="#" @click="showProfileMenu = !showProfileMenu">
            <span class="circle">{{ user.Name[0] + user.Surname[0] }}</span>
            <span>
              <small>Benvenuto</small>
              <br />
              <strong>{{ user.Name + " " + user.Surname }}</strong>
            </span>
          </a>
          <div v-if="showProfileMenu" id="profileMenu" ref="clickOutside">
            <ul>
              <li>
                <RouterLink to="/profile"><i class="fal fa-user" /> Profilo</RouterLink>
              </li>
              <li>
                <a href="cms/images/manuale.pdf" download><i class="fal fa-download" /> Manuale</a>
              </li>
              <li>
                <a href="mailto:support@smart-flow.it"><i class="fal fa-hands-helping" /> Assistenza</a>
              </li>
              <li>
                <a @click="showChangelog"><i class="fal fa-file-alt" /> ChangeLog</a>
              </li>
              <li>
                <RouterLink to="/logout"><i class="fal fa-sign-out" /> Logout</RouterLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <div v-if="showMenu" class="content">
    <div v-if="user && user.ID" id="col_sx">
      <nav>
        <ul v-if="userLoaded" id="primo_menu">
          <li v-if="isActiveUser" class="li_fal">
            <RouterLink to="/" :class="{ 'router-link-active': ['home', 'documents'].includes(currentRoute.name) }"
              class="fal fa-file" />
          </li>
          <!-- <li v-if="user.Liv == 2" class="li_fal">
            <RouterLink to="/history" class="fal fa-history" />
          </li> -->
          <li class="li_fal">
            <RouterLink to="/publics" class="fal fa-globe" :class="{ 'router-link-active': ['Public', 'publicDetail'].includes(currentRoute.name)}" />
          </li>
          <li class="li_fal">
            <RouterLink to="/approved" class="fal fa-atlas" :class="{ 'router-link-active': ['Approved', 'approvedDetail'].includes(currentRoute.name)}" />
          </li>
        </ul>
      </nav>
    </div>
    <div id="page-wrapper">
      <RouterView />
    </div>
  </div>
  <div v-else>
    <RouterView />
  </div>
</template>
<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { RouterLink, RouterView, useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/stores/user";
import { onClickOutside } from "@vueuse/core";
import moment from "moment";
import Table from "@/components/Table.vue";
import Modal from "@/components/Modal.vue";
import _ from "lodash";
import axios from "axios";
import { storeToRefs } from "pinia";
const currentRoute = useRoute();
const userStore = useUserStore();
const router = useRouter();
let { user } = storeToRefs(userStore);
const changelogCols = [
  { field: "Name", headerName: "Riferimento", sortable: true },
  { field: "Descr", headerName: "descrizione" },
  { field: "Insert_Date", headerName: "Data Creazione", sortable: true },
];
const currentPage = ref(null);
const showMenu = ref(false);
const showProfileMenu = ref(false);
const bannerTxt = ref(null);
const changelog = ref(null);
const clickOutside = ref(null);
const userLoaded = ref(false);
onClickOutside(clickOutside, () => (showProfileMenu.value = false));
onMounted(() => {
  axios.post('/site/find', { where: { Name: 'banner' } }).then(({ data }) => {
    if (data.success && data.data.length && data.data[0].Active == 1) {
      bannerTxt.value = data.data[0].Descr;
    }
  }).catch((err) => {
    console.log(err);
  });
});
watch(currentRoute, (n) => {
  console.log('watch route');
  showMenu.value = !["login", "logout"].includes(n.name);
  switch (n.name) {
    case "home":
    case "documents":
      currentPage.value = "Elenco Documenti" + (n.params.documentID ? " > <b>Documento " + n.params.documentID + "</b>" : "");
      break;
    case "Editor":
      currentPage.value = "Elenco Azioni Editor";
      break;
    case "History":
      currentPage.value = "Documenti Annullati";
      break;
    case "Public":
      currentPage.value = "Documenti Pubblicati";
      break;
    case 'Approved':
      currentPage.value = "Documenti non Pubblici";
      break;
    case "Profile":
      currentPage.value = "Profilo";
      break;
  }
},{flush: 'pre',immediate:true});
router.beforeEach(async (to, from, next) => {
  let tmp = null;
  if (to.path == '/testapi') {
    next();
    return;
  }
  if (localStorage.getItem('auth') !== null) {
    tmp = JSON.parse(localStorage.getItem('auth'));
    axios.defaults.headers.common['Authorization'] = tmp.Token;
    userStore.setUser(tmp);
  }
  let data;
  try {
    ({ data } = await axios.post('/is_logged'));
    console.log('islogged');
  }catch(e) {
    console.log(e);
  }
  if (data.session) {
    userStore.setUser(data.session);
    axios.defaults.headers.common['Authorization'] = data.session.Token;
    
    if(parseInt(data.session.Liv) == 2 || data.session?.isEditor || data.session?.isValidator) {
      if (to.name != 'Login') next();
      else next('/');
    }else{
      if(['publicDetail','Public'].includes(to.name)) next();
      else next('/publics');
    }
    userLoaded.value=true;
  } else {
    user.value = null;
    localStorage.removeItem('auth');
    if (import.meta.env.PROD) {
      window.location.href = "https://sgi.retelit.it/simplesaml/module.php/core/as_login.php?AuthId=irideos&ReturnTo=https%3A%2F%2Fsgi.retelit.it/cms/api/it/checklogin";
    } else {
      if (to.name != 'Login') {
        next('/login');
      } else {
        next();
      }
    }
  }
});

const showChangelog = () => {
  axios
    .post("/changelog/show_changelog", {
      where: { Active: 1, Created_From: "IA" },
    })
    .then(({ data }) => {
      if (data.success) {
        changelog.value = _.map(data.data, (o) => {
          return {
            ID: o.ID,
            Name: o.Name,
            Insert_Date: moment(o.Insert_Date).format("DD-MM-YYYY"),
            Descr: o.Descr,
          };
        });
      } else {
        console.log("Server returned an error:", data.error);
      }
    })
    .catch((err) => {
      console.log("Server returned an error:", err);
    });
};
const isActiveUser = computed(() => {
  if(!userLoaded.value) return false;
  console.log('isActive');
  if(user && user.value.Liv==2) return true;
  return user.value.Liv != 2 && (user.value.isEditor || user.value.isValidator);
});
</script>
<style lang="scss">
@import "@/css/font-awesome.min.css";
@import "@/css/font-awesome5.min.css";
@import "@/css/grid.scss";
@import "@/css/base.scss";
@import "@/css/responsive.scss";
</style>
